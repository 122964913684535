import { IconButton, Menu } from "@material-ui/core";
import { Fragment, useState } from "react";
import { MeatBallsVertical } from "src/v3/theme/icons/general";
import { MenuPaperProps } from "./style";
import { ActionMenuProps } from "./types";
import IconComponent from "../Icons/IconComponent";

function ActionMenu({
  actions,
  icon,
  size = "small",
}: ActionMenuProps): JSX.Element {
  if (!actions || actions.length === 0) {
    throw new Error("ActionMenu: actions is required");
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton
        onClick={handleClick}
        size={size}
        aria-controls={anchorEl ? "action-menu" : undefined}
        aria-haspopup="true"
      >
        {icon ? icon : <IconComponent Icon={MeatBallsVertical} />}
      </IconButton>

      <Menu
        id="action-menu-item-row"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: { ...MenuPaperProps, gap: "8px" },
        }}
      >
        {actions.map((action) => (
          <div onClick={handleClose}>{action}</div>
        ))}
      </Menu>
    </Fragment>
  );
}

export default ActionMenu;
