import styled from "styled-components";
import { Theme } from "@material-ui/core";

export const ChallengeContent = styled.div<{
  theme: Theme;
  onMouseUp: () => {};
}>`
  color: ${({ theme }) => theme.palette.grey[900]};
  font-family: ${({ theme }) => theme.typography.body2.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body2.fontSize} !important;
  font-weight: ${({ theme }) => theme.typography.body2.fontWeight} !important;
  line-height: ${({ theme }) => theme.typography.body2.lineHeight} !important;
  font-style: normal;
  cursor: ${({ onMouseUp }) => (onMouseUp ? "pointer" : "default")};

  & a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const Root = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.grey[600]};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  width: 100%;
`;

export const RiskHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  flex: 1 0 0;
`;

export const RiskGrid = styled.div<{ isLast: boolean }>`
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing(3)};
  display: flex;
  flex-direction: column;
  border-bottom: ${({ isLast, theme }) =>
    isLast ? "none" : `1px solid ${theme.palette.grey[300]}`};
`;

export const RiskTitle = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.palette.grey[900]};
  font-family: ${({ theme }) => theme.typography.body1.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body1.fontSize} !important;
  font-weight: ${({ theme }) => theme.typography.body1.fontWeight} !important;
  line-height: ${({ theme }) => theme.typography.body1.lineHeight} !important;
`;

export const RiskStatus = styled.div`
  color: ${({ theme }) => theme.palette.grey[400]};
  font-family: ${({ theme }) => theme.typography.body2.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body2.fontSize} !important;
  font-weight: ${({ theme }) => theme.typography.body2.fontWeight} !important;
  line-height: ${({ theme }) => theme.typography.body2.lineHeight} !important;
  text-align: right;
`;

export const ExpandedRiskContent = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.spacing(1, 8)};
  color: ${({ theme }) => theme.palette.grey[900]};
`;
