import { BlockItem } from "src/v2/domain/entities/block/BlockItem";

export function findItemByKey(
  items: BlockItem[],
  key: string
): BlockItem | null {
  for (const item of items) {
    if (item.getKey() === key) return item;
    if (item.hasChildren()) {
      const foundItem = findItemByKey(item.getChildren(), key);
      if (foundItem) return foundItem;
    }
  }

  return null;
}
