import React, { Fragment } from "react";
import { CustomAccordion } from "src/v3/components/Accordion";
import { IssueTypeIcon } from "src/v3/components/IssueTypeIcon";
import { ItemName } from "src/v3/components/commomStyles/ItemName";
import { AccordionItemsPadding } from "src/v3/components/commomStyles/AccordionItemsPadding";
import { ItemRow } from "src/v3/components/ItemRow";
import { StatusChipTypes } from "src/v3/components/StatusChip/types";
import EmptyState from "src/v3/components/EmptyState";
import {
  ItemNameHeader,
  ProductListRoot,
  RenderAccordionItemRoot,
} from "./styles";
import { SortContainer } from "src/v3/dataContainers/SortContainer";
import { aggregateItemsByProjectStatus } from "src/v3/utils/ProjectStatus/aggregateItemsByPS";
import { useProductList } from "./useProductList";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { ProductListProps } from "./types";
import { BreadCrumbsBuilder } from "src/v3/components/Breadcrumbs/builder";
import { AccordionHeaderRoot } from "src/v3/components/commomStyles/AccordionHeaderRoot";
import { truncateStringInTheEnd } from "src/utils/stringUtils";
import { ProgressBar } from "src/v3/components/ProgressBar";
import { BandwidthSideInfo } from "src/v3/components/commomStyles/ItemRowSideInfos/Bandwidth";
import { TargetDateSideInfo } from "src/v3/components/commomStyles/ItemRowSideInfos/TeamsTargetDate";
import { IssueType } from "src/v3/components/IssueTypeIcon/types";
import { ItemHeaderProgressBar } from "src/v3/components/ProgressBar/style";
import { useBandwidth } from "src/v3/hooks/useBandwidth";
import { useItemDetailNavigation } from "src/v3/providers/useItemDetailNavigation";

const ProductListAccordionHeader = React.memo(
  ({ item }: { item: BlockItem }) => {
    const projectStatusAggregation = aggregateItemsByProjectStatus({
      items: item.getChildren(),
    });

    const { handleOpenItemDetails } = useItemDetailNavigation({ item });

    return (
      <AccordionHeaderRoot key={item.getKey()}>
        <IssueTypeIcon type={item.type.displayName as IssueType} size="small" />
        <ItemName
          customStyle={{
            cursor: "pointer",
          }}
        >
          <ItemNameHeader
            onClick={(e) => {
              e.stopPropagation();
              handleOpenItemDetails();
            }}
          >
            {truncateStringInTheEnd(item.displayName, 70)}
          </ItemNameHeader>
        </ItemName>
        <ItemHeaderProgressBar>
          <ProgressBar aggregation={projectStatusAggregation} />
        </ItemHeaderProgressBar>
      </AccordionHeaderRoot>
    );
  }
);

export const RenderItemRow = ({
  item,
  bandwidthMap,
}: {
  item: BlockItem;
  bandwidthMap: Record<string, number>;
}) => {
  const bandwidth = bandwidthMap[item.getKey()] || 0;

  const actions = [];
  const statusField = item.getProjectStatusField();
  if (statusField) {
    actions.push(statusField.getEditStatusAction(item));
  }

  const summaryOfWorkField = item.getSummaryOfWorkField();
  if (summaryOfWorkField) {
    actions.push(summaryOfWorkField.getEditSummaryOfWorkAction(item));
  }

  const { handleOpenItemDetails } = useItemDetailNavigation({ item });

  return (
    <ItemRow
      key={item.getKey()}
      displayName={item.displayName}
      itemKey={item.getKey()}
      summaryField={summaryOfWorkField}
      statusField={statusField}
      issueType={item.type}
      statusChipType={StatusChipTypes.ProjectStatus}
      sideInfos={[
        <TargetDateSideInfo item={item} />,
        <BandwidthSideInfo bandwidth={bandwidth} />,
      ]}
      actions={actions}
      onNameClick={handleOpenItemDetails}
    />
  );
};

const RenderAccordionItem = React.memo(({ item }: { item: BlockItem }) => {
  const { bandwidthMap } = useBandwidth(item.getChildren());
  const [isAccordionExpanded, setIsAccordionExpanded] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const handleToggleAccordion = React.useCallback(() => {
    setIsAccordionExpanded((prev) => !prev);
  }, []);

  return (
    <RenderAccordionItemRoot ref={ref}>
      <CustomAccordion
        header={<ProductListAccordionHeader item={item} />}
        isExpanded={isAccordionExpanded}
        onToggle={handleToggleAccordion}
        hasChildren={item.hasChildren()}
      >
        <AccordionItemsPadding>
          {SortContainer({ items: item.getChildren() }).map(
            (childItem: BlockItem) => (
              <RenderItemRow item={childItem} bandwidthMap={bandwidthMap} />
            )
          )}
        </AccordionItemsPadding>
      </CustomAccordion>
    </RenderAccordionItemRoot>
  );
});

const Products = React.memo(({ items }: ProductListProps) => {
  const sortedItems = React.useMemo(() => SortContainer({ items }), [items]);
  return (
    <>
      {sortedItems.map((item: BlockItem, index: number) => (
        <ProductListRoot key={index}>
          <RenderAccordionItem item={item} />
        </ProductListRoot>
      ))}
    </>
  );
});

export const ProductList = React.memo(({ items }: ProductListProps) => {
  const { filteredItems, groups } = useProductList({ items });

  if (!items?.length || (groups?.length && !filteredItems?.length)) {
    return <EmptyState message="No items match the given filter" />;
  }

  if (filteredItems?.length) {
    const hasGroup = filteredItems.some(
      ({ groupedItems }) => groupedItems?.length
    );
    return (
      <>
        {filteredItems.map(({ name, groupedItems, filteredItems }) => {
          if (hasGroup) {
            if (!groupedItems?.length) {
              return null;
            }
            return (
              <Fragment key={name}>
                <BreadCrumbsBuilder type="group" groups={[name]} />
                {groupedItems.map(({ items, fieldValue }) => (
                  <Products key={fieldValue} items={items} />
                ))}
              </Fragment>
            );
          }
          return <Products key={name} items={filteredItems} />;
        })}
      </>
    );
  }

  return <Products items={items} />;
});
