import { Typography, MenuItem } from "@material-ui/core";
import theme from "src/v3/theme/theme";
import styled from "styled-components";

export const CustomMenuItem = styled(MenuItem)`
  display: flex;
  align-self: stretch;
  min-width: 130px;
  height: 100%;
`;

export const ActionLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[900]};
  cursor: pointer;
  width: 100%;
`;

export const MenuPaperProps = {
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[100]}`,
  boxShadow: theme.shadows[1],
  backgroundColor: theme.palette.background.default,
  display: "flex",
  padding: theme.spacing(1, 0.5),
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(1.3),
  width: "135px",
};
