import styled from "styled-components";

export const TeamsListRoot = styled.div`
  border-radius: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2, 0)};
`;

export const TeamListFakeAccordion = styled.div`
  width: 100%;
`;

export const TeamHeaderProgressBar = styled.div`
  display: flex;
  width: 23%;
  height: 10px;
  justify-content: flex-end;
  align-items: center;
`;
