import {
  MenuItem,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Select,
  Divider,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { useState } from "react";
import BaseButton from "src/components/Buttons/BaseButton";
import { EventTypes } from "src/providers/EventContext";
import { useOverrideContext } from "src/providers/OverrideContext";
import setErrorSnackbar, {
  setSuccessSnackbar,
} from "src/services/setErrorSnackbar";
import CustomDialog from "src/v2/components/CustomDialog";
import { BlockItem } from "../../block/BlockItem";
import * as sessionService from "src/services/sessionService";
import { updateRisks } from "src/services/v2/insightsService";
import { mapWeeksToDate } from "src/v3/components/Dialogs/ProjectStatusEdit/utils";
import { WarningTriangle } from "src/v3/theme/icons/general";
import { ResolveChallengeWarningIcon } from "src/v3/components/Icons/ResolveChallendWarning";

export const ArchiveRiskModal = ({
  risk,
  item,
}: {
  risk: any;
  item: BlockItem;
}) => {
  const { saveOverride } = useOverrideContext();

  const user = sessionService.getLoggedUser();

  const [open, setOpen] = useState(false);

  const [justification, setJustification] = useState("");
  const [justificationTouched, setJustificationTouched] = useState(false);
  const [isSaving, setIsDeleting] = useState(false);
  const [retainStatus, setRetainStatus] = useState(false);
  const defaultExpirationDateInDays = 10;
  const [expirationDate, setExpirationDate] = useState("2 weeks");

  const handleExpirationDateChange = (e) => {
    setExpirationDate(e.target.value);
  };

  const handleRetainStatusChange = (event) => {
    setRetainStatus(event.target.checked);
  };

  const handleJustificationChange = (event) => {
    setJustification(event.target.value);
  };

  const handleBlurJustification = () => {
    if (!justificationTouched && justification.trim() === "") {
      setJustificationTouched(true);
    }
  };

  const resetState = (e) => {
    e.stopPropagation();
    setJustification("");
    setJustificationTouched(false);
    setRetainStatus(false);
    setIsDeleting(false);
    setExpirationDate("2 weeks");
    setOpen(false);
  };

  const onConfirm = async (e) => {
    e.stopPropagation();

    const _expirationDate = retainStatus
      ? mapWeeksToDate(expirationDate)
      : new Date(
          new Date().setDate(new Date().getDate() + defaultExpirationDateInDays)
        );

    const updatedValue = {
      id: risk.id,
      title: risk.title?.replace(/\s*\(\d+\)$/, ""),
      group: risk.group,
      severity: risk.severity,
      action: "delete",
      author: {
        name: user?.name,
        email: user?.email,
      },
    };
    const riskOverride = {
      value: updatedValue,
      retainOverride: retainStatus,
      expirationDate: _expirationDate,
      justification: justification,
      fieldKey: item.getRiskField()?.fieldKey,
      blockColumn: item.getRiskField()?.fieldKey,
      id: risk.id,
      createdAt: new Date(),
    };

    try {
      await updateRisks(riskOverride, item.getKey());

      saveOverride({
        item,
        overrides: [riskOverride],
        event: {
          type: EventTypes.RISK_DELETED,
          message: `Risk has being deleted by ${user?.name} on ${new Date().toLocaleDateString()} for ${item.getKey()}`,
        },
      });
      setSuccessSnackbar("Risk deleted successfully!", 3000);
    } catch (error) {
      console.error(error);
      setErrorSnackbar(
        "Failed to delete this risk, please try again later, if the issue persists, contact us.",
        3000
      );
      resetState(e);
    } finally {
      setOpen(false);
      setIsDeleting(false);
    }
  };

  const expirationWeekOptions = ["1 week", "2 weeks", "3 weeks", "4 weeks"];

  return (
    <div>
      <Typography
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(true);
        }}
        style={{
          color: `#1A7BBD`,
          textAlign: "center",
          marginTop: 32,
          marginBottom: 8,
          textUnderlineOffset: 4,
          textDecoration: "underline",
          fontWeight: 600,
          fontSize: "15px",
          cursor: "pointer",
          lineHeight: "16px",
          font: "inherit",
        }}
      >
        Delete this risk
      </Typography>
      {open && (
        <CustomDialog
          open={open}
          onClose={resetState}
          title="Delete Risk"
          customTitleStyle={{
            color: "#2C485B",
            fontSize: "16px",
            lineHeight: "19px",
            fontWeight: 700,
          }}
        >
          {
            <Grid container direction="column">
              <Grid container alignItems="center">
                <ResolveChallengeWarningIcon />
                <Typography
                  style={{
                    fontWeight: "700",
                    lineHeight: "16.8px",
                  }}
                >
                  Are you sure you want to delete this risk?
                </Typography>
              </Grid>
              <Grid container direction="column">
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "16.8px",
                    marginTop: 32,
                  }}
                >
                  Justification (optional)
                </Typography>
                <FormControl variant="outlined" required>
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    placeholder="Let Velma know why you are deleting the risk."
                    variant="outlined"
                    value={justification}
                    onChange={handleJustificationChange}
                    onBlur={handleBlurJustification}
                    inputProps={{ maxLength: 250 }}
                    style={{
                      width: "100%",
                      marginTop: "8px",
                      borderRadius: "4px",
                      border: `1px solid "#D9D9D9"`,
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid
                container
                direction="column"
                style={{
                  width: "100%",
                  marginTop: 16,
                }}
              >
                <FormControlLabel
                  style={{
                    marginTop: "8px",
                  }}
                  control={
                    <Checkbox
                      key={`retainStatus-${Math.random()}`}
                      checked={retainStatus}
                      onChange={handleRetainStatusChange}
                      color="primary"
                    />
                  }
                  label={
                    <Typography
                      style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "16.8px",
                        textAlign: "left",
                      }}
                    >
                      Delete this risk for a certain duration
                    </Typography>
                  }
                />
                {retainStatus && (
                  <FormControl variant="outlined">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={expirationDate}
                      onChange={handleExpirationDateChange}
                      required
                      style={{
                        height: "33px",
                        marginTop: "8px",
                        borderRadius: "4px",
                        width: "208px",
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {expirationWeekOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>
          }
          <Divider
            style={{
              marginTop: "20px",
            }}
          ></Divider>
          <Grid container justifyContent="flex-end">
            <BaseButton
              style={{
                backgroundColor: "white",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                border: "1px solid #1A7BBD",
                color: "#2C485B",
                marginRight: "10px",
                marginTop: "20px",
              }}
              onClick={resetState}
            >
              Cancel
            </BaseButton>
            {isSaving ? (
              <div
                style={{
                  alignSelf: "center",
                  width: "50px",
                }}
              >
                <CircularProgress
                  style={{
                    alignSelf: "center",
                    marginTop: "20px",
                    width: "24px",
                    height: "24px",
                  }}
                ></CircularProgress>
              </div>
            ) : (
              <BaseButton
                style={{
                  backgroundColor: "#1A7BBD",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  color: "#FFFFFF",
                  marginTop: "20px",
                }}
                onClick={async (e) => {
                  setIsDeleting(true);
                  await onConfirm(e);
                }}
              >
                Yes
              </BaseButton>
            )}
          </Grid>
        </CustomDialog>
      )}
    </div>
  );
};
