import { FieldConfigType } from "src/v2/domain/enum/FieldConfigType";
import { PriorityField } from "../PriorityField";
import { StatusField } from "../StatusField";
import { StatusField as WorkflowStatusField } from "src/v3/entities/CustomFields/StatusField";
import { ChangelogByDateField } from "../ChangelogByDateField";
import { DropDownField } from "../DropDownField";
import { DateField } from "../DateField";
import { DependencyField } from "../DependencyField";
import { ProjectStatusField } from "../ProjectStatusField";
import { TeamField } from "../TeamField";
import { TextField } from "../TextField";
import { CustomFieldBase } from "../CustomFieldBase";
import { ExpandableTextField } from "../ExpandableText";
import { RisksField } from "../Risk/RisksField";
import { OnlyReadOnFilter } from "../OnlyReadOnFilter";
import { ProjectedFinishField } from "../ProjectedFinishField";
import { LabelField } from "../LabelField";
import { MoneyField } from "../MoneyField";
import { AssigneeField } from "../AssigneeField";
import { ParentLinkField } from "../ParentLinkField";
import { LinkField } from "../LinkField";
import { HTMLField } from "../AISummary/HTMLField";
import { AllocationField } from "../AllocationField";
import { CondensedNameField } from "../CondensedNameField";
import { DetailedNameField } from "../DetailedNameField";
import { RiskComparisonDateField } from "../RiskComparisonDateField";
import { CondensedProgressField } from "../CondensedProgressField";
import { DetailedProgressField } from "../DetailedProgressField";
import { CostField } from "../CostField";
import { AddedField } from "../AddedField";
import { TeamLinkField } from "../TeamLinkField";
import { LastJiraUpdatedDateField } from "../LastJiraUpdatedDateField";
import { OutcomeExpectedCompletion } from "../Outcome/OutcomeExpectedCompletion";
import { OutcomeStatus } from "../Outcome/OutcomeStatus";
import { OutcomeField } from "../Outcome/OutcomeField";
import { OutcomeReason } from "../Outcome/OutcomeReason";
import { AISlackSummaryHTML } from "../AISummary/AISlackSummaryHTML";

// V3 Fields
import { SummaryOfWorkField } from "src/v3/entities/CustomFields/SummaryOfWork";
import { ProjectStatusField as ProjectStatusFieldV3 } from "src/v3/entities/CustomFields/ProjectStatusField";
import { TargetDateField } from "src/v3/entities/CustomFields/TargetDateField";
import { CommentsField } from "src/v3/entities/CustomFields/CommentsField";
import { SprintField } from "src/v3/entities/CustomFields/SprintField";

export class CustomFieldFactory {
  static getCustomFieldInstance(field): CustomFieldBase {
    switch (field.type) {
      case FieldConfigType.assignee:
        return new AssigneeField(field);
      case FieldConfigType.changelog:
        return new ChangelogByDateField(field);
      case FieldConfigType.date:
        return new DateField(field);
      case FieldConfigType.label:
        return new LabelField(field);
      case FieldConfigType.dropdown:
        return new DropDownField(field);
      case FieldConfigType.dependency:
        return new DependencyField(field);
      case FieldConfigType.priority:
        return new PriorityField(field);
      case FieldConfigType.progress:
        return new DetailedProgressField(field);
      case FieldConfigType.project_status:
        return new ProjectStatusField(field);
      case FieldConfigType.project_status_insights:
        return new ProjectStatusField(field);
      case FieldConfigType.condensed_project_status_insights:
        return new ProjectStatusField(field);
      case FieldConfigType.detailed_project_status_insights:
        return new ProjectStatusField(field);
      case FieldConfigType.status:
        return new StatusField(field);
      case FieldConfigType.team:
        return new TeamField(field);
      case FieldConfigType.expandable_text:
        return new ExpandableTextField(field);
      case FieldConfigType.name:
        return new DetailedNameField(field);
      case FieldConfigType.parentLink:
        return new ParentLinkField(field);
      case FieldConfigType.risks:
        return new RisksField(field);
      case FieldConfigType.text:
        return new TextField(field);
      case FieldConfigType.link:
        return new LinkField(field);
      case FieldConfigType.read_on_filter:
        return new OnlyReadOnFilter(field);
      case FieldConfigType.projected_finish:
        return new ProjectedFinishField(field);
      case FieldConfigType.money:
        return new MoneyField(field);
      case FieldConfigType.html:
        return new HTMLField(field);
      case FieldConfigType.ai_slack_summary:
        return new AISlackSummaryHTML(field);
      case FieldConfigType.allocation:
        return new AllocationField(field);
      case FieldConfigType.cost:
        return new CostField(field);
      case FieldConfigType.risk_comparison_date:
        return new RiskComparisonDateField(field);
      case FieldConfigType.condensed_name:
        return new CondensedNameField(field);
      case FieldConfigType.condensed_progress:
        return new CondensedProgressField(field);
      case FieldConfigType.children_added_dates:
        // DEPRECATED - use parent_added_date instead
        return new AddedField(field);
      case FieldConfigType.parent_added_date:
        return new AddedField(field);
      case FieldConfigType.team_link:
        return new TeamLinkField(field);
      case FieldConfigType.comments:
        return new CommentsField(field);
      case FieldConfigType.last_jira_updated_date:
        return new LastJiraUpdatedDateField(field);
      case FieldConfigType.outcome:
        return new OutcomeField(field);
      case FieldConfigType.outcome_expected_completion:
        return new OutcomeExpectedCompletion(field);
      case FieldConfigType.outcome_status:
        return new OutcomeStatus(field);
      case FieldConfigType.outcome_reason:
        return new OutcomeReason(field);

      // V3 Fields
      case FieldConfigType.summary_of_work:
        return new SummaryOfWorkField(field);
      case FieldConfigType.workflow_status:
        return new WorkflowStatusField(field);
      case FieldConfigType.project_status_v3:
        return new ProjectStatusFieldV3(field);
      case FieldConfigType.target_date:
        return new TargetDateField(field);
      case FieldConfigType.sprint:
        return new SprintField(field);
      default: {
        console.log({ field });
        throw new Error(
          `Can not instantiate Custom Field: ${field.displayName} with type: ${field.type}`
        );
      }
    }
  }
}
